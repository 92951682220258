<template>
    <div class="system-roles list-page page">

        <div class="page--list" style="background:#fff;">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <list-tool-bar>
                    <el-button type="primary" style="margin-bottom:15px;" v-if="auth.update" @click.stop="handleCheckUpdate">上传更新数据</el-button>
                    <el-button type="primary" style="margin-bottom:15px;" v-if="auth.check" @click.stop="handleCheckVerifyCalCust">验证新老客户</el-button>
                    <el-button type="primary" style="margin-bottom:15px;" v-if="auth.checkNo" @click.stop="handleCheckVerifyCalCustNo">按成交单验证新老客户</el-button>

                </list-tool-bar>
                <el-row style="flex-wrap: wrap;padding:16px 16px 0 16px;background:#F4F7FA;" class="flex">
                    <div style="margin-right:32px;margin-bottom:16px;">
                        <el-input placeholder="请输入内容" maxlength="30" v-model.trim="search_value" class="input-with-select">
                            <el-select style="width:120px;" v-model="search_type" slot="prepend" placeholder="请选择">
                                <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </div>
                    <div style="margin-bottom:16px;;margin-right:32px;">
                        <el-select class="width1h" style="width:120px;margin-right:8px;" @change="changetimetype" v-model="search_type2" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in searchTypeEnum2" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                        <el-date-picker :type="datetype" class="width2" v-model="searchStartTime" :value-format="valueformat" placeholder="请选择"></el-date-picker>
                        <span> - </span>
                        <el-date-picker :type="datetype" class="width1h" v-model="searchEndTime" :value-format="valueformat" placeholder="请选择"></el-date-picker>
                    </div>
                    <!-- <div style="display:flex;align-items: center;margin-right:32px;margin-bottom:16px;">
                        <div style="width:120px;text-align:right;color:#606266;padding-right:12px;">合同状态 </div>
                        <el-select v-model="search_type3" placeholder="请选择" style="width:202px;">
                            <el-option v-for="item in searchTypeEnum3" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </div> -->
                    <div style="margin-bottom:16px;">
                        <el-button style="" type="primary" @click.stop="handleSubmit">搜索</el-button>
                        <el-button @click.stop="handleClear">清空</el-button>
                    </div>
                </el-row>

                <el-table :data="tableData" @selection-change="handleSelectionChange">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column label="业务方向" prop="sysCodeName" ></el-table-column>
                    <el-table-column label="成交单号" prop="orderNo"></el-table-column>
                    <el-table-column label="客户编号" prop="custCode"></el-table-column>
                    <el-table-column label="成交日期" prop="dealTime"></el-table-column>
                    <el-table-column label="核算月份" prop="calTime"></el-table-column>
                    <el-table-column label="是否核算新客户" prop="newCustName"></el-table-column>
                    <el-table-column label="更新时间" prop="updateTime">
                        <template slot-scope="scope">
                            <div v-html="scope.row.updateTime"></div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作"
                        fixed="right">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="true||auth.save"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >详情</el-button>
                             
                                <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>

        <!-- 验证新老客户 -->
        <el-dialog class="verifyDia"
        :visible="showVerifyDia"
        @close="showVerifyDia= false"
        title="验证新老客户"
        width="560px">
            <div class="tips">
                <span>温馨提示：导入验证的表格第一列必须是客户编号，验证的结果在表格最后一列进行显示。</span>
            </div>
            <div style="margin-bottom:8px;display:flex;"> 
                <span style="margin-right:15px;margin-top:10px;"><span style="color:red;">*</span> 上传文件</span> 
                <el-upload
                :on-exceed="handleExceed"
                :limit='1'
                class="upload-demo"
                :headers='uploadHeaders'
                ref="upload"
                :action="$baseURL+'/api/calcustomer/verifyCalCustFile'"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-success="handleUploadSuccess"
                :on-change="handleFileChange"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <el-button slot="trigger"  type="text" style="margin-left: 10px;" 
                        @click.stop="downLoadVerifyfile"
                    >下载模板</el-button>
                 
                </el-upload>
            </div>

            <div slot="footer">
                <el-button @click="cancelCheckAchievement" >取消</el-button>
                <el-button @click="submitUpload"  type="primary">开始验证</el-button>
            </div>
        </el-dialog>
        <!-- 上传更新数据 -->
        <el-dialog class="updateDia"
        :visible="showUpdateDia"
        @close="showUpdateDia= false"
        title="更新已核算新老客户数据"
        width="560px">
        <div style="margin-bottom:8px;display:flex;"> 
                <span style="margin-right:15px;margin-top:10px;"><span style="color:red;">*</span> 业务方向</span> 
                <el-select v-model="up_search_type" slot="prepend" placeholder="请选择">
                    <el-option v-for="item in searchTypeEnum4" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
                
            </div>
                    <!-- :data="{test: 1}" -->
                <!-- :data="uploadObjs" -->

            <!--  -->
            
            <div style="margin-bottom:8px;display:flex;"> 
                <span style="margin-right:15px;margin-top:10px;"><span style="color:red;">*</span> 上传文件</span> 
                <el-upload
                :on-exceed="handleExceed"
                :limit='1'
                class="upload-demo"
                :headers='uploadHeaders'
                ref="upload2"
                :action="$baseURL+'/api/calcustomer/uploadCalCustFile'"
                :on-preview="handlePreview2"
                :on-remove="handleRemove2"
                :file-list="fileList2"
                :before-upload="onBeforeUpload"
                :data="uploadObjs"
                :on-success="handleUploadSuccess2"
                :on-change="handleFileChange2"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                 <el-button slot="trigger"  type="text" style="margin-left: 10px;" 
                        @click.stop="downLoadfile"
                    >下载模板</el-button>
                </el-upload>
            </div>

            <div slot="footer">
                <el-button @click="cancelCheckUpdate" >取消</el-button>
                <el-button @click="submitUpload2"  type="primary">确定</el-button>
            </div>
        </el-dialog>

         <!-- 上传更新数据 -->
         <el-dialog class="updateNo"
        :visible="showUpdateNo"
        @close="showUpdateNo= false"
        title="按成交单验证新老客户数据"
        width="500px">
            <div class="tips">
                <div style="width: 90px;height: 30px;">
                    <span>温馨提示：</span>
                </div>
                <div style="height: 30px;">
                    <span>导入验证的表格第一列必须是认购单编号/签约单编号/订单编号。验证的结果在表格最后一列进行显示。</span>
                </div>
                
            </div>
        <div style="margin-bottom:8px;display:flex;"> 
                <span style="margin-right:15px;margin-top:10px;"><span style="color:red;">*</span> 业务方向</span> 
                <el-select v-model="up_no_type" slot="prepend" placeholder="请选择">
                    <el-option v-for="item in searchTypeEnum4" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
                
            </div>
                    <!-- :data="{test: 1}" -->
                <!-- :data="uploadObjs" -->

            <!--  -->
            
            <div style="margin-bottom:8px;display:flex;"> 
                <span style="margin-right:15px;margin-top:10px;"><span style="color:red;">*</span> 上传文件</span> 
                <el-upload
                :on-exceed="handleExceed"
                :limit='1'
                class="upload-demo"
                :headers='uploadHeaders'
                ref="uploadNo"
                :action="$baseURL+'/api/calcustomer/calCustomerByOrderNo'"
                :on-preview="handlePreview2"
                :on-remove="handleRemove2"
                :file-list="fileListNo"
                :before-upload="onBeforeUploadNo"
                :data="uploadNoObjs"
                :on-success="handleUploadSuccessNo"
                :on-change="handleFileChangeNo"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                 <el-button slot="trigger"  type="text" style="margin-left: 10px;" 
                        @click.stop="downLoadNofile"
                    >下载模板</el-button>
                </el-upload>
            </div>

            <div slot="footer">
                <el-button @click="cancelNoUpdate" >取消</el-button>
                <el-button @click="submitUploadNo"  type="primary">确定</el-button>
            </div>
        </el-dialog>
        <!-- 更新回调弹窗 -->
        <el-dialog class="updatedia1"
        :visible="updatedia1"
        @close="updatedia1= false"
        title="提示"
        width="560px">
            <div style="margin-bottom:8px;display:flex;"> 
                <span>本次上传{{updianum1}}条数据，其中成功{{updianum2}}条，失败{{updianum3}}条，有{{updianum4}}条成交数据已经核算，是否需要修改已核对数据，以本次导入为准</span> 
            </div>
            <div slot="footer">
                <el-button @click="cancelupdatedia1" >取消</el-button>
                <el-button v-if='updianum4>0' @click="submitUpdiabtn1"  type="primary" style="padding-top:2px;">
                    <div style="margin-bottom:2px;">确定更新</div>
                    <div style="font-size:12px;">(已核对数据)</div>
                </el-button>
                <el-button @click="submitUpdiabtn2"  type="primary" style="padding-top:2px;">
                    <div style="margin-bottom:2px;">下载查看</div>
                    <div style="font-size:12px;">(已核算数据)</div>
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            search_value: "",
            search_type: 1,
            search_type2: 1,
            search_type3: -1,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            searchStartTime: "",
            searchEndTime: "",

            searchTypeEnum: [
                { name: "成交单号", value: 1 },
                { name: "客户编号", value: 2 },
            ],
            searchTypeEnum2: [{ name: "成交日期", value: 1 },{ name: "核算月份", value: 2 }],
            searchTypeEnum3: [
                { name: "请选择", value: -1 },
                { name: "签署中", value: 1 },
                { name: "已完成", value: 2 },
                { name: "已撤销", value: 3 },
                { name: "已失效", value: 4 },
            ],
            searchTypeEnum4: [
                { name: "请选择", value: -1 },
                { name: "房产", value: 'CRM' },
                { name: "移民", value: 'AYM' },
                { name: "债券", value: 'MON'},
            ],
            up_search_type:-1,
            multipleSelection: "",
            sysCode: "",
            auth:{
               save:false ,
               update:false,
               check:false,
               checkNo:false
            },
            valueformat:'yyyy-MM-dd',
            datetype:'date',
            showVerifyDia: false,//验证新老客户
            fileList: [],
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },

            showUpdateDia: false,//上传更新数据
            fileList2: [],
            
            uploadObjs:{
                sysCode:'',
                isConfirm:0,
            },
            uploadNoObjs:{
                sysCode:''
            },
            updatedia1:false,
            updianum1:0,
            updianum2:0,
            updianum3:0,
            updianum4:0,
            upBackfilePathName:'',
            up_no_type:-1,
            fileListNo: [],
            showUpdateNo: false,//按成交单验证新老客户数据
        };
    },
    created() {
            let url = window.location.href;
            console.log(url);
            console.log(this.$route);
        if (this.$route.query.sysCode) {
            this.sysCode = this.$route.query.sysCode;
        } else {
            this.sysCode = "";
            let urlQuery = this.getUrlQuery();
            if (urlQuery.sysCode) {
                this.sysCode = urlQuery.sysCode;
                this.$store.commit("setShowMenu", { showMenu: false }); //业务系统来的隐藏菜单
            } else {
                this.sysCode = "";
                this.$store.commit("setShowMenu", { showMenu: true }); //显示菜单
            }
        }
        this.auth.save = this.$hasAuthFor("api/channelContract/cacenlChannelContract"); //
        this.auth.check = this.$hasAuthFor("api/calcustomer/verifyCalCustFile"); //
        this.auth.update = this.$hasAuthFor("api/calcustomer/uploadCalCustFile"); //
        this.auth.checkNo = this.$hasAuthFor("api/calcustomer/calCustomerByOrderNo"); //
        


        this.fetchData();
        this.getConfigByType();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        // 上传更新数据  开始
        handleCheckUpdate() {
            this.showUpdateDia = true
            setTimeout(() => {
                this.$refs.upload2.clearFiles();
                this.fileList2 = []
                this.up_search_type =-1
            }, 20);
        },
         handlePreview2(file) {
            console.log(file);
        },
         handleRemove2(file, fileList) {
            console.log(file, fileList);
        },
        onBeforeUpload() {
			// 可以在上传前的钩子函数中添加额外参数
			this.uploadObjs.sysCode = this.up_search_type
		},
        onBeforeUploadNo() {
			// 可以在上传前的钩子函数中添加额外参数
			this.uploadNoObjs.sysCode = this.up_no_type
		},
        // 二次确认
        async agineupload(sysCode,filePathName){
            const res2 = await this.$axios({
                url: "/api/calcustomer/uploadCalCustFile",
                method: "post",
                data:{
                    sysCode:sysCode,
                    isConfirm:1,
                    filePathName:filePathName
                }
            })
            if (res2.code === 2000) {
                this.$message({
                    message: '更新成功',
                    type: "success"
                });

                setTimeout(() => {
                    this.$refs.upload2.clearFiles();
                    this.fileList2 = []
                    this.up_search_type =-1
                    this.showUpdateDia = false
                    this.updatedia1 = false
                    this.fetchData();
                }, 500);
            
            } 

        },
        // 更新已核算新老客户数据
        async handleUploadSuccess2(res, file) {
            console.log(res);
            console.log(file);
            this.uploading.close();
            if (res.code == 2001) {
                this.$message({
                    message: res.msg || "文件有误",
                    type: "warning"
                });
                setTimeout(() => {
                    this.$refs.upload2.clearFiles();
                    this.fileList2 = []
                    // this.up_search_type =-1
                }, 20);
            }
             if (res.code == 2000) {
                this.upBackfilePathName = res.data.filePathName
                this.updianum1 = res.data.allNum||0
                this.updianum2 = res.data.successNum||0
                this.updianum3 = res.data.errorNum||0
                this.updianum4 = res.data.confirmNum||0
                this.updatedia1 = true
            }
        },
        // 更新按成交单验证新老客户数据
        async handleUploadSuccessNo(res, file) {
            console.log(res);
            console.log(file);
            this.uploading.close();
            if (res.code == 2001) {
                this.$message({
                    message: res.msg || "文件有误",
                    type: "warning"
                });
                setTimeout(() => {
                    this.$refs.uploadNo.clearFiles();
                    this.fileListNo = []
                    // this.up_search_type =-1
                }, 20);
            }
             if (res.code == 2000) {
                setTimeout(() => {
                    this.$refs.uploadNo.clearFiles();
                    this.fileListNo = []
                    this.showUpdateNo = false
                   
                }, 500);
                setTimeout(() => {
                    if (res.data.path) {
                        window.open(this.$baseURL + '/' + res.data.path, "download");
                    }
                }, 2000);
            }
        },
         handleFileChange2(files) {
            this.fileList2 = [files]
        },
        handleFileChangeNo(files) {
            this.fileListNo = [files]
        },
        
         async downLoadNofile() {
            let loadingRef,
                url = '/api/calcustomer/downCheckNewCustTemplate'
            loadingRef = this.$loading();

            const res = await this.$axios({
                method: "post", url, data: {}
            })
            console.log(res);
            if (res.code === 2000) {
                // this.$message({
                //     message: "下载成功",
                //     type: "success"
                // });
                if (res.data.filePath) {
                    window.open(this.$baseURL + '/' + res.data.filePath, "download");
                }
                loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },
        
         async downLoadVerifyfile() {
            let loadingRef,
                url = '/api/calcustomer/downCalNewCustTemplate'
            loadingRef = this.$loading();

            const res = await this.$axios({
                method: "post", url, data: {}
            })
            console.log(res);
            if (res.code === 2000) {
                // this.$message({
                //     message: "下载成功",
                //     type: "success"
                // });
                if (res.data.filePath) {
                    window.open(this.$baseURL + '/' + res.data.filePath, "download");
                }
                loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },
        async downLoadfile() {
            let loadingRef,
                url = '/api/calcustomer/downCusCustTemplate'
            loadingRef = this.$loading();

            const res = await this.$axios({
                method: "post", url, data: {}
            })
            console.log(res);
            if (res.code === 2000) {
                // this.$message({
                //     message: "下载成功",
                //     type: "success"
                // });
                if (res.data.filePath) {
                    window.open(this.$baseURL + '/' + res.data.filePath, "download");
                }
                loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },
        submitUpload2() {
             if (this.up_search_type == -1 ) {
                this.$message({
                    message: "请选择业务方向",
                    type: "warning"
                });
            }else if (this.fileList2.length == 0) {
                this.$message({
                    message: "请选择上传文件",
                    type: "warning"
                });
            } else {
                this.uploading = this.$loading({
                    target: ".updateDia .el-dialog"
                });
                this.$refs.upload2.submit();
            }
        },
        submitUploadNo() {
             if (this.up_no_type == -1 ) {
                this.$message({
                    message: "请选择业务方向",
                    type: "warning"
                });
            }else if (this.fileListNo.length == 0) {
                this.$message({
                    message: "请选择上传文件",
                    type: "warning"
                });
            } else {
                this.uploading = this.$loading({
                    target: ".updateNo .el-dialog"
                });
                this.$refs.uploadNo.submit();
            }
        },
        cancelCheckUpdate() {
            this.showUpdateDia = false
            this.$refs.upload2.clearFiles();
            this.fileList2 = []
            this.up_search_type =-1
        },
        cancelNoUpdate() {
            this.showUpdateNo = false
            this.$refs.uploadNo.clearFiles();
            this.fileListNo = []
            this.up_no_type =-1
        },
        cancelupdatedia1() {
            this.showUpdateDia = false
            this.$refs.upload2.clearFiles();
            this.fileList2 = []
            this.updatedia1 = false
        },
        // 确定更新
        submitUpdiabtn1(){
            this.agineupload(this.up_search_type,this.upBackfilePathName)
        },
        // 下载查看
         submitUpdiabtn2(){
            this.showUpdateDia = false
            this.$refs.upload2.clearFiles();
            this.fileList2 = []
            this.updatedia1 = false
            this.up_search_type =-1
            this.fetchData();
            if (this.upBackfilePathName) {
                window.open(this.$baseURL + '/' + this.upBackfilePathName, "download");
            }
        },
        // 上传更新数据  结束


        // 验证新老客户
        handleCheckVerifyCalCust() {
            this.showVerifyDia = true
            setTimeout(() => {
                this.$refs.upload.clearFiles();
                this.fileList = []
            }, 20);
        },
        // 按成交单验证新老客户

        handleCheckVerifyCalCustNo() {
            this.showUpdateNo = true
            setTimeout(() => {
                this.$refs.uploadNo.clearFiles();
                this.fileListNo = [];
                this.up_no_type=-1;
                this.uploadNoObjs={};
            }, 20);
        },
        handleExceed() {
            this.$message({
                message: `最多只能上传1个文件`,
                type: "error",
            });
        },
        cancelCheckAchievement() {
            this.showVerifyDia = false
            this.$refs.upload.clearFiles();
            this.fileList = []
        },
         handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleFileChange(files) {
            this.fileList = [files]
        },
        // 验证新老客户
        handleUploadSuccess(res, file) {
            console.log(res);
            console.log(file);
            this.uploading.close();
            if (res.code == 2001) {
                this.$message({
                    message: res.msg || "文件有误",
                    type: "warning"
                });
                setTimeout(() => {
                    this.$refs.upload.clearFiles();
                    this.fileList = []
                }, 20);
            }
            if (res.code == 2000) {
                this.$message({
                    message: '验证成功',
                    type: "success"
                });
                setTimeout(() => {
                    this.$refs.upload.clearFiles();
                    this.fileList = []
                    this.showVerifyDia = false
                    this.fetchData();
                }, 500);
                setTimeout(() => {
                    if (res.data.json.path) {
                        window.open(this.$baseURL + '/' + res.data.json.path, "download");
                    }
                }, 2000);
            }
        },
        
        submitUpload() {
            if (this.fileList.length == 0) {
                this.$message({
                    message: "请选择上传文件",
                    type: "warning"
                });
            } else {
                this.uploading = this.$loading({
                    target: ".verifyDia .el-dialog"
                });
                this.$refs.upload.submit();
            }
        },
        // ~~```````````````````````````````````````````````````````````````````````````

        changetimetype(data){
            console.log(data);
            this.searchStartTime = ''
            this.searchEndTime = ''
            if(data==2){
                this.valueformat = 'yyyy-MM'
                this.datetype='month'
            }else{
                this.valueformat = 'yyyy-MM-dd'
                this.datetype='date'
            }
        },
        // 获取url参数
        getUrlQuery() {
            // CH-AYM29
            // let  url=  'http://192.168.110.136:8020/#/customer/channelPhoneList/add?id=16&chanCode=CH-AYM29&sysCode=AYM'
            let url = window.location.href;
            if(url.indexOf("?") != -1 ){
                let urlStr = url.split("?")[1];
                // 创建空对象存储参数
                let obj = {};
                // 再通过 & 将每一个参数单独分割出来
                let paramsArr = urlStr.split("&");
                for (let i = 0, len = paramsArr.length; i < len; i++) {
                    // 再通过 = 将每一个参数分割为 key:value 的形式
                    let arr = paramsArr[i].split("=");
                    obj[arr[0]] = arr[1];
                }
                return obj;
            }else{
                return {};
            }

            
        },
        // 获取查群参数
        async getConfigByType() {
            const res = await this.$axios({
                url: "/api/channelContract/listParam",
                method: "post",
                data: {
                    type: 2, //字典类型 1协议 2行业
                    pid: 0, //父级id
                },
            });
            if (res.code === 2000) {
                //
                //  [
                //     { name: "请选择", value: -1 },
                //     { name: "签署中", value: 1 },
                //     { name: "已完成", value: 2 },
                //     { name: "已撤销", value: 3 },
                //     { name: "已失效", value: 4 },

                // ] ||
                //
                this.searchTypeEnum3 = res.data.ChannelContractStateEnum || [];
            }
        },

        async handleAddBtn(data) {
            // multipleSelection
            if (this.multipleSelection.length == 0) {
                this.$message({
                    message: "请选择需要撤销的合同",
                    type: "warning",
                });
                return;
            }
            let _has = this.multipleSelection.findIndex((element) => {
                return element.stateName !== "签署中";
            });
            console.log(_has);
            if (_has >= 0) {
                this.$message({
                    message: "仅可撤销签署中的合同",
                    type: "warning",
                });
                return;
            }

            let idarr = [];
            this.multipleSelection.forEach((item) => {
                idarr.push(item.id);
            });

            let loadingRef;
            try {
                await this.$confirm("确定要撤销合同吗", "系统提示");
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channelContract/cacenlChannelContract",
                    method: "post",
                    data: {
                        ids: idarr.join(),
                    },
                });
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        download(path) {
            let headurl = ''
            if(path.length>4){
                headurl = path.slice(0,4)
            }
            if(headurl =='http'){
                window.open(path, "download");
            }else{
                window.open(this.$baseURL + path, "download");
            }
        },
        // 详情
        handleDetail(data) {
            // this.$router.push({
            // 	path: `/customer/channelPhoneList/Detail`,
            // 	query: {id: data.id}
            // });
            this.$router.push({
                path: `/customer/channelPhoneList/add`,
                query: { id: data.id },
            });
        },
        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示");
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/resource/deleteResource1",
                    method: "post",
                    data: {
                        resourceid: parseInt(data.id),
                    },
                });
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleSubmit() {
            this.pageNum = 1;
            this.pageSize = 10;
            this.fetchData();
        },
        handleClear() {
            this.pageNum = 1;
            this.pageSize = 10;
            this.search_value = "";
            this.search_type = 1;
            this.search_type2 = 1;
            this.search_type3 = "";
            this.searchStartTime = ''
            this.searchEndTime = ''
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/calcustomer/queryCalculateCustomerList",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    searchType: this.search_type,
                    searchValue: this.search_value,
                    searchTimeType: this.search_type2,
                    searchBeginTime: this.searchStartTime,
                    searchEndTime: this.searchEndTime,
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.tableData = res.data.data || [];
                // this.tableData.forEach(element => {
                //     if(this.userInfo.employeeId == element.createEmp){
                //         element.candown=true
                //     }else{
                //         element.candown=false
                //     }
                // });
                this.total = res.data.count || 0;
            } else {
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning",
                });
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>


.header {
    height: 40px;
}
// 公用
.pl16 {
    padding-left: 16px;
}
.pr16 {
    padding-right: 16px;
}
.pt24 {
    padding-top: 24px;
}
.pb24 {
    padding-bottom: 24px;
}
.ml12 {
    margin-left: 12px;
}
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex1 {
    flex: 1;
}
.a-center {
    align-items: center;
}
.fb {
    font-weight: 600;
}
.f16 {
    font-size: 16px;
}
.hover {
    cursor: pointer;
}
.tips {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        color: #D40000;
    }
// 公用完
</style>